import { MessageDescriptor } from 'react-intl';

export enum SkeletonType {
    _,
    ProjectList,
    ProjectCard,
    HomeCards,
    DataGrid,
    Jobs,
    Header,
    Collar,
    Configuration,
}

export interface ContentLoadingProps {
    isFailed: boolean;
    timeout?: number;
    message?: MessageDescriptor;
    skeletonLoading?: boolean;
    fullAppSkeleton?: boolean;
    type?: SkeletonType;
}

export interface NavBarProps {
    isDrawerOpen: boolean;
}

export const defaultTimeout = 5_000;
export const defaultMessage: MessageDescriptor = {
    id: 'content-loading.message',
    defaultMessage: 'Loading, please wait',
};